@import "../../../css-config/mixins.scss";

.newsletter-section {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    background: #f9f9f9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // Remaining styles...
    .newsletter-content{
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:0 auto;
        text-align: center  ;
    }
    .small-text{
        margin-bottom: 15px;
        text-transform: uppercase;
        color: rgba(0,0,0,0.5);
    }
    .big-text{
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 30px;
        @include md{
            font-size: 28px;    
        }
    }
    .form{
        display: flex;
        gap: 5px;
        margin-bottom: 10px;
        input{
            width: 200px;
            height: 40px;
            border-radius: 0;
            border: 1px solid  rgba(0,0,0,0.2);
            padding: 0 12px;
            font-size: 16px;
            outline: 0;
            @include md{
                width: 300px;
            }
        }
        button{
            outline: 0;
            border: 0;
            height: 40px;
            width: 60px; 
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 15px;
            color: rgb(0, 0, 0);
            background-color: #d67311;
            border-bottom: 1px solid #d67311;  
            @include md{
                font-size: 17px;
                width: 73px;
            }    
        }
    }
    .text{
        margin-bottom: 20px;
        font-size: 16px;
        color: rgba(0,0,0,0.5);
    };

    .social-icons{
        display: flex;
        gap: 10px;
        .icons{
            width: 30px;
            height: 30px; 
            background-color: rgba(0,0,0,0.8);
            border-radius: 50%;
            color: white;
            display: flex;
            justify-content: center ;
            align-items: center;
        }
    }
}
