/* src/components/Header/Header.scss */
@import "../../css-config/mixins.scss";

.main-header {
    width: 100%;
    padding: 0 20px;
    background-color: #f6f3ee;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 99;

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        max-width: 1200px;
        margin: 0 auto;

        @include md {
            height: 80px;
        }

        .left {
            list-style-type: none;
            display: none;
            gap: 25px;

            @include md {
                display: flex;
            }

            li {
                font-size: 15px;
                font-weight: 500;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                text-transform: uppercase;
                cursor: pointer;
                position: relative;

                &.category-menu {
                    .dropdown {
                        visibility: hidden;
                        opacity: 0;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        background: #fff;
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        overflow: hidden;
                        transform: translateY(-10px);
                        transition: visibility 0s, opacity 0.3s ease, transform 0.3s ease;
                        z-index: 100;

                        li {
                            padding: 10px 20px;
                            white-space: nowrap;
                            cursor: pointer;

                            &:hover {
                                background: #f0f0f0;
                            }
                        }
                    }

                    &:hover .dropdown {
                        visibility: visible;
                        opacity: 1;
                        transform: translateY(0);
                        transition-delay: 0s;
                    }
                }
            }

            &.show {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 60px;
                left: 0;
                width: 100%;
                background-color: #f6f3ee;
                padding: 10px 0;
                z-index: 10;

                li {
                    padding: 10px 20px;
                }

                .category-menu .dropdown {
                    position: static;
                    transform: none;
                    box-shadow: none;

                    li {
                        padding: 10px 20px;
                    }
                }
            }
        }

        .center {
            font-size: 22px;
            font-weight: 700;
            font-family: 'Times New Roman', Times, serif;
            cursor: pointer;

            @include md {
                font-size: 34px;
                position: absolute;
                left: 51%;
                transform: translateX(-50%);
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 20px;

            @include md {
                gap: 25px;
            }

            svg {
                font-size: 20px;
                cursor: pointer;

                @include md {
                    font-size: 24px;
                }
            }
        }

        .mobile-menu-icon {
            display: flex;
            font-size: 24px;
            cursor: pointer;

            @include md {
                display: none;
            }
        }
    }

    &.sticky-header {
        position: sticky;
        top: 0;
        animation: stickyHeader 0.3s ease forwards;
        transform: translateY(-80px);
    }
}

@keyframes stickyHeader {
    0% {
        transform: translateY(-80px);
    }

    100% {
        transform: translateY(0);
    }
}
