// src/components/ScrollToTop/ScrollToTop.scss
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f6f3ee;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;

    svg {
        width: 24px;
        height: 24px;
        color: #333;
    }

    &.show {
        opacity: 1;
        visibility: visible;
    }

    &:hover {
        background-color: #e0c200;
    }
}
