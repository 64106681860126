@import "../../css-config/mixins.scss";

.products-container{
    margin: 50px 0;
    @include md{
        margin: 75px 0;
    }
    .section-heading{
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        @include md{
                margin-bottom: 35px;
                font-size: 24px
        }
    }
    .products{
        display: flex;
        flex-flow: wrap;
        gap: 10px;
        @include md{
                gap: 20px;
        }
    }
}