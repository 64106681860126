/* src/components/About/About.scss */
.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 40px 20px;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('1.png'); /* Replace with your image path */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1; /* Ensure it stays behind the content */
        filter: blur(5px); /* Optional: add a slight blur for better readability */
        transform: scale(1.1); /* Slightly enlarge the image for better coverage */
    }

    .about-content {
        max-width: 800px;
        background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
        padding: 40px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        text-align: left;
        z-index: 1; /* Ensure it stays above the background */

        h1 {
            margin-bottom: 20px;
            font-size: 2.5em;
            color: #333;
            text-align: center;
        }

        .about-text {
            p {
                margin-bottom: 20px;
                line-height: 1.6;
                font-size: 1.1em;
                color: #555;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .about-content {
            max-width: 700px;
            padding: 30px;

            h1 {
                font-size: 2.2em;
            }

            .about-text p {
                font-size: 1em;
            }
        }
    }

    @media (max-width: 768px) {
        .about-content {
            max-width: 600px;
            padding: 30px;

            h1 {
                font-size: 2em;
            }

            .about-text p {
                font-size: 1em;
            }
        }
    }

    @media (max-width: 480px) {
        .about-content {
            max-width: 100%;
            padding: 20px;

            h1 {
                font-size: 1.5em;
            }

            .about-text p {
                font-size: 0.9em;
            }
        }
    }
}
