@import "../../../css-config/mixins.scss";

/* src/components/Hero/Hero.scss */
.hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;

    @include sm {
        height: 70vh;
    }

    @include md {
        height: 80vh;
    }

    @include lg {
        height: 90vh;
    }

    @include xl {
        height: 100vh;
    }

    @include xxl {
        height: 100vh;
    }

    .hero-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .hero-content {
        position: relative;
        color: white;
        text-align: center;
        z-index: 1;
        padding: 20px;

        h1 {
            font-size: 2em; /* Default for small screens */
            margin-bottom: 20px;

            @include sm {
                font-size: 2.5em;
            }

            @include md {
                font-size: 3em;
            }

            @include lg {
                font-size: 3.5em;
            }

            @include xl {
                font-size: 4em;
            }

            @include xxl {
                font-size: 3.5em;
            }
        }

        p {
            font-size: 1em; /* Default for small screens */
            margin-bottom: 30px;

            @include sm {
                font-size: 1.2em;
            }

            @include md {
                font-size: 1.3em;
            }

            @include lg {
                font-size: 1.5em;
            }

            @include xl {
                font-size: 1.7em;
            }

            @include xxl {
                font-size: 1.5em;
            }
        }

        button {
            padding: 6px 12px; /* Default for small screens */
            font-size: 1em;
            background-color: #f6f3ee;
            border: none;
            cursor: pointer;
            transition: background-color 0.3s ease;
            border-radius: 5px;

            &:hover {
                background-color: #e0c200;
            }

            @include sm {
                padding: 8px 16px;
                font-size: 1.1em;
            }

            @include md {
                padding: 10px 20px;
                font-size: 1.2em;
            }

            @include lg {
                padding: 12px 24px;
                font-size: 1.3em;
            }

            @include xl {
                padding: 14px 28px;
                font-size: 1.4em;
            }

            @include xxl {
                padding: 16px 32px;
                font-size: 1.5em;
            }
        }
    }
}
